import T from 'prop-types';

const frameShape = T.shape({
  price:            T.number.isRequired,
  frameName:        T.string,
  frame:            T.string,
  frameMat:         T.number,
  frameWidth:       T.number,
  frameHeight:      T.number,
  frameThickness:   T.number,
  imageUrl:         T.string.isRequired,
  frameId:          T.number,
  customFrame:      T.bool,
  previewWidth:     T.number.isRequired,
  previewHeight:    T.number.isRequired,
  frameThumbUrl:    T.string,
  framePreviewUrl:  T.string,
  frameLargeImageUrl:T.string,
  frameFinalFramedSize: T.string,
  frameDisplayWidth: T.number,
  frameDisplayDepth: T.string,
  zoomPreviewUrl:   T.string,
  frameCategory:    T.string,
  warning:          T.string
});

const paperShape =       T.shape({
  paperId:               T.number.isRequired,
  paperMaterial:         T.string.isRequired,
  paperWidth:            T.number.isRequired,
  paperHeight:           T.number.isRequired,
  displayPaperWidth:     T.number.isRequired,
  displayPaperHeight:    T.number.isRequired,
  displayPaperWidthNoMat:T.number,
  displayPaperHeightNoMat:T.number,
  available:             T.bool,
  printWidth:            T.number.isRequired,
  printHeight:           T.number.isRequired,
  options:               T.arrayOf(frameShape).isRequired,
  isHidden:              T.bool,
  limitations:           T.string
});

const workShape = T.shape({
  id:               T.number,
  authorName:       T.string,
  title:            T.string,
  imageUrl:         T.string,
  description:      T.string,
  currency:         T.string,
  prices:           T.arrayOf(T.oneOfType([T.number, T.string])),
  aboutTheAuthor:   T.string,
  authorId:         T.number,
  aboutPrints:      T.arrayOf(T.string),
  aboutPaper:       T.arrayOf(T.string),
  secondPreviewUrl: T.string,
  workSizes:        T.arrayOf(paperShape),
  landscape:        T.bool,
  limitedEdition:   T.bool,
  isStock:          T.bool,
  firstPaper:       T.shape({
    paperId:        T.number.isRequired,
    paperMaterial:  T.string.isRequired,
    paperWidth:     T.number.isRequired,
    paperHeight:    T.number.isRequired,
    available:      T.bool,
    printWidth:     T.number.isRequired,
    printHeight:    T.number.isRequired
  }),
  subject:          T.string,
  href:             T.string,
  isHidden:         T.bool,
  notAvailable:     T.bool,
  allowStores:      T.bool,
  storeItemId:      T.number
});

const thumbnailsShape = T.arrayOf(T.shape({
  type: T.string,
  url: T.string,
  selection: T.shape({
    frame: frameShape,
    paper: paperShape
  })
}));

export {
  workShape,
  paperShape,
  frameShape,
  thumbnailsShape
};
