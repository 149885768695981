import React from 'react';

import AsyncRoute from './async_route';

import * as Classes from './styles.module.scss';
import VisitManager from './visit_manager';
import SubscribePopupCounters from '../subscribe_popup_counters';

const QueryOrderStatus = React.lazy(() => import('../query_order_status/query_order_status'));
const ProductsList = React.lazy(() => import('arts/components/ProductsList'));
const Product = React.lazy(() => import('arts/components/Product'));
const MyWalls = React.lazy(() => import('arts/components/my_walls'));
const MyFavorites = React.lazy(() => import('arts/components/my_favorites'));
const Walls = React.lazy(() => import('arts/components/walls'));
const Wall = React.lazy(() => import('arts/components/wall'));
const PopularSearchCategories = React.lazy(() => import('arts/components/popular_search_categories'));
const WallArts = React.lazy(() => import('arts/components/wall_arts'));
const WallArt = React.lazy(() => import('arts/components/wall_art'));
const Category = React.lazy(() => import('arts/components/category'));
const Style = React.lazy(() => import('arts/components/category/style'));
const Color = React.lazy(() => import('arts/components/category/color'));
const Medium = React.lazy(() => import('arts/components/category/medium'));
const Orientation = React.lazy(() => import('arts/components/category/orientation'));
const Price = React.lazy(() => import('arts/components/category/price'));
const Size = React.lazy(() => import('arts/components/category/size'));
const NewArrivals = React.lazy(() => import('arts/components/new_arrivals'));
const WorksShow = React.lazy(() => import('arts/components/WorksShow'));
const FineArtPrints = React.lazy(() => import('arts/components/fine_art_prints'));
const Tapestries = React.lazy(() => import('../tapestries'));
const OriginalArt = React.lazy(() => import('../original_art'));
const OriginalArts = React.lazy(() => import('../original_arts'));
const GiftCards = React.lazy(() => import('arts/components/gift_cards/gift_cards'));
const GiftCard = React.lazy(() => import('arts/components/gift_cards/card/gift_card'));
const GiftCardBalance = React.lazy(() => import('arts/components/gift_cards/balance'));
const Artists = React.lazy(() => import('arts/components/artists'));
const ArtistWorks = React.lazy(() => import('arts/components/ArtistWorks'));
const Collections = React.lazy(() => import('arts/components/collections').then(module => ({ default: module.Collections })));
const DesignerPicks = React.lazy(() => import('arts/components/collections').then(module => ({ default: module.DesignerPicks })));
const GiftGuides = React.lazy(() => import('arts/components/collections').then(module => ({ default: module.GiftGuides })));
const Inspirations = React.lazy(() => import('arts/components/inspirations'));
const CollectionDetails = React.lazy(() => import('arts/components/collection_details'));
const ReviewCartItems = React.lazy(() => import('../checkout/review_cart_items'));
const SignInToCheckout = React.lazy(() => import('../checkout/sign_in_to_checkout'));
const EditAddresses = React.lazy(() => import('../checkout/edit_addresses'));
const EditOrder = React.lazy(() => import('../edit_order'));
const NewOrderPayment = React.lazy(() => import('../new_order_payment'));
const EditOrderPayment = React.lazy(() => import('../edit_order_payment'));
const ThankYou = React.lazy(() => import('../checkout/thank_you'));
const StaticPage = React.lazy(() => import('../static_page/static_page'));
const MyReferral = React.lazy(() => import('../my_referral'));
const PromoRewards = React.lazy(() => import('../my_referral/promo_rewards'));
const ReferralCode = React.lazy(() => import('../referral_code'));
const VisualSearch = React.lazy(() => import('../visual_search'));
const PromoSubscribe = React.lazy(() => import('../promo_subscribe'));
const Explore = React.lazy(() => import('../explore'));
const SearchQuery = React.lazy(() => import('../search_query'));
const Giveaway = React.lazy(() => import('../giveaway'));
const DefaultRoute = React.lazy(() => import('./default_route'));

const Routes = () => (
  <>
    <VisitManager>
      {
        ({ visitsCount, pathname }) => (
          <SubscribePopupCounters visitsCount={ visitsCount } pathname={ pathname } />
        )
      }
    </VisitManager>
    <div className={ Classes.arts }>
      <AsyncRoute exact path="/query_order_status" component={ QueryOrderStatus } />

      <AsyncRoute exact path="/trios" component={ ProductsList } />
      <AsyncRoute exact path="/trios/:id" component={ Product } />

      <AsyncRoute exact path="/stylist/walls" component={ MyWalls } />
      <AsyncRoute exact path="/my/favorites" component={ MyFavorites } />

      <AsyncRoute exact path="/walls" component={ Walls } />
      <AsyncRoute exact path="/walls/style/:with_home_decor_style_id" component={ Walls } />
      <AsyncRoute exact path="/walls/room/:with_room_id" component={ Walls } />
      <AsyncRoute exact path="/walls/:id" component={ Wall } />
      <AsyncRoute exact path="/walls/:id/:slug" component={ Wall } />

      <AsyncRoute exact path="/popular-search-categories" component={ PopularSearchCategories } />

      <AsyncRoute exact path="/wall-art" component={ WallArts } />
      <AsyncRoute exact path="/wall-art/:slug" component={ WallArt } />

      <AsyncRoute exact path="/art-prints/category/:categorySlug" component={ Category } />
      <AsyncRoute exact path="/art-prints/style/:styleSlug" component={ Style } />
      <AsyncRoute exact path="/art-prints/style/:styleSlug/:categorySlug" component={ Style } />
      <AsyncRoute exact path="/art-prints/medium/:mediumSlug" component={ Medium } />
      <AsyncRoute exact path="/art-prints/price/:priceSlug" component={ Price } />
      <AsyncRoute exact path="/art-prints/:colorSlug" component={ Color } />
      <AsyncRoute exact path="/art-prints/size/:sizeSlug" component={ Size } />
      <AsyncRoute exact path="/art-prints/orientation/:orientationSlug" component={ Orientation } />

      <AsyncRoute exact path="/new-arrivals" component={ NewArrivals } />
      <AsyncRoute exact path="/art-prints/product/:workSlug" component={ WorksShow } />
      <AsyncRoute exact path="/art-prints" component={ FineArtPrints } />

      <AsyncRoute exact path="/tapestries" component={ Tapestries } />

      <AsyncRoute exact path="/original_arts/:id/:slug" component={ OriginalArt } />
      <AsyncRoute exact path="/original_arts/:id" component={ OriginalArt } />
      <AsyncRoute exact path="/original_arts" component={ OriginalArts } />

      <AsyncRoute exact path="/gift_cards" component={ GiftCards } />
      <AsyncRoute exact path="/gift_cards/card" component={ GiftCard } />
      <AsyncRoute exact path="/gift_cards/balance_inquiry" component={ GiftCardBalance } />

      <AsyncRoute exact path="/artists" component={ Artists } />
      <AsyncRoute exact path="/artists/:artistId" component={ ArtistWorks } />
      <AsyncRoute exact path="/users/:artistId" component={ ArtistWorks } />
      <AsyncRoute exact path="/artists/:artistId/:slug" component={ ArtistWorks } />
      <AsyncRoute exact path="/users/:artistId/:slug" component={ ArtistWorks } />

      <AsyncRoute exact path="/collections" component={ Collections } />
      <AsyncRoute exact path="/inspirations" component={ Inspirations } />
      <AsyncRoute exact path="/designer_picks" component={ DesignerPicks } />
      <AsyncRoute exact path="/gift_guides" component={ GiftGuides } />
      <AsyncRoute exact path="/collections/:slug" component={ CollectionDetails } />

      <AsyncRoute exact path="/cart_items" component={ ReviewCartItems } />
      <AsyncRoute exact path="/my/order" component={ SignInToCheckout } />
      <AsyncRoute exact path="/my/address/edit" component={ EditAddresses } />
      <AsyncRoute exact path="/orders/:id/edit" component={ EditOrder } />
      <AsyncRoute exact path="/orders/:id/payment/new" component={ NewOrderPayment } />
      <AsyncRoute exact path="/orders/:id/payment" component={ NewOrderPayment } />
      <AsyncRoute exact path="/orders/:id/payment/edit" component={ EditOrderPayment } />
      <AsyncRoute exact path="/orders/:id/thankyou" component={ ThankYou } />

      <AsyncRoute exact path="/about" component={ StaticPage } />
      <AsyncRoute exact path="/contact_us" component={ StaticPage } />
      <AsyncRoute exact path="/sell_with_us" component={ StaticPage } />
      <AsyncRoute exact path="/artist_faq" component={ StaticPage } />
      <AsyncRoute exact path="/artist_agreement" component={ StaticPage } />
      <AsyncRoute exact path="/copyright_policy" component={ StaticPage } />
      <AsyncRoute exact path="/shipping_info" component={ StaticPage } />
      <AsyncRoute exact path="/return_policy" component={ StaticPage } />
      <AsyncRoute exact path="/terms_of_use" component={ StaticPage } />
      <AsyncRoute exact path="/privacy" component={ StaticPage } />
      <AsyncRoute exact path="/customer_faq" component={ StaticPage } />
      <AsyncRoute exact path="/technical_guidelines" component={ StaticPage } />
      <AsyncRoute exact path="/affiliate_agreement" component={ StaticPage } />
      <AsyncRoute exact path="/revshare" component={ StaticPage } />

      <AsyncRoute exact path="/my/referral" component={ MyReferral } />
      <AsyncRoute exact path="/my/referral/rewards" component={ PromoRewards } />
      <AsyncRoute exact path="/ref/:token" component={ ReferralCode } />

      <AsyncRoute exact path="/more-like-this/:id" component={ VisualSearch } />

      <AsyncRoute exact path="/get-promo-code" component={ PromoSubscribe } />
      <AsyncRoute exact path="/visual-search" component={ Explore } />
      <AsyncRoute exact path="/visual-search/:slug" component={ SearchQuery } />
      <AsyncRoute exact path="/giveaway" component={ Giveaway } />
      <AsyncRoute component={ DefaultRoute } />
    </div>
  </>
);

export default Routes;
