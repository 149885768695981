import React, {
  Suspense, useState, useTransition, useContext
} from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

export const LoadingComponent = () => (<div />);

// Create a context to track component loading state
export const RouteLoadingContext = React.createContext({
  setRouteLoaded: () => {},
  isRouteLoaded: false
});

const AsyncRoute = ({ component: Component, ...rest }) => {
  const [isPending, startTransition] = useTransition();
  const [mounted, setMounted] = useState(false);
  const { setRouteLoaded } = useContext(RouteLoadingContext);

  // Use startTransition to avoid suspension during synchronous updates
  React.useEffect(() => {
    startTransition(() => {
      setMounted(true);
    });
  }, []);

  // Signal when component is mounted and ready
  React.useEffect(() => {
    if (mounted && !isPending) {
      setRouteLoaded(true);
    }

    return () => {
      // Reset when unmounting
      setRouteLoaded(false);
    };
  }, [mounted, isPending, setRouteLoaded]);

  return (
    <Route
      { ...rest }
      render={
        (props) => (
          <Suspense fallback={ <LoadingComponent /> }>
            { mounted && <Component { ...props } /> }
          </Suspense>
        )
      }
    />
  );
};

AsyncRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};

export default AsyncRoute;
