import React from 'react';

const TutorialIcon = (props) => (
  <svg
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <rect x="1" y="1" width="29" height="24" stroke="black" fill="none" />
    <path d="M1 6.5H6.5M30 6.5H27M27 6.5L24 1M27 6.5H20M20 6.5L17 1M20 6.5H13.5M13.5 6.5L10 1M13.5 6.5H6.5M6.5 6.5L3.5 1" stroke="black" />
    <path d="M21 15.5L12.75 20.2631L12.75 10.7369L21 15.5Z" fill="black" stroke="black" />
  </svg>
);

export default TutorialIcon;
