import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import Routes from './routes';
import SearchIcon from './search_icon';
import CartItemsPopup from '../cart_items_popup';
import LocaleSelection from '../../../shared/locale_selection';
import CookiesConsent from '../../../shared/cookies_consent';
import { RouteLoadingContext } from './async_route';
import SmartFooter from '../footer/smart_footer';

const history = createBrowserHistory();

const Application = ({ store }) => {
  const [isRouteLoaded, setRouteLoaded] = useState(false);

  const routeLoadingContextValue = useMemo(() => ({
    isRouteLoaded,
    setRouteLoaded
  }), [isRouteLoaded, setRouteLoaded]);

  return (
    <Provider store={ store }>
      <RouteLoadingContext.Provider value={ routeLoadingContextValue }>
        <Router history={ history } basename={ window.currentLocale.basename }>
          <Routes />
        </Router>
        <SearchIcon history={ history } />
        <CartItemsPopup />
        <LocaleSelection />
        <CookiesConsent />

        {
          isRouteLoaded && (
            <div id="footer" className="footer">
              <SmartFooter />
            </div>
          )
        }
      </RouteLoadingContext.Provider>
    </Provider>
  );
};

Application.propTypes = {
  store: PropTypes.object.isRequired
};

export default Application;
